jQuery(document).ready(function($){

	$('.lightGallery').lightGallery({
		selector: 'a',
		fullScreen: true,
		closable: true,
		hash: false,
		videoMaxWidth: '1920px',
		showThumbByDefault: false,
		thumbnail: false
	});

});
