jQuery(document).ready(function($){

    function puffGrid() {
        var grid = $(".puff-grid-container");
        var cols = $(grid).children(".wpb_column");
        var total =  Math.round(grid.width() / cols.outerWidth());
        if(grid.find(".clear-float-puff").length > 0) {
            $(".clear-float-puff").remove();
        }
        $.each(cols, function(index, el) {
            if(total-1 === index % total && total > 1) {
                $("<div class='clear-float-puff' style='clear:both;'></div>").insertAfter(el);
            }
        })

        // console.log(Math.round( (cols.outerWidth() / grid.width()) * 100 ));
        // console.log(Math.round( ( /) * 100 ), cols.width(), grid.width());
    }

    $(window).on("resize", function() {
        puffGrid();
    });
    puffGrid();





    $.each($(".tb-puffs, .tb-booking-puffs"), function() {
        var src = "";
        var container = $(this).find(".img-container");

        if($(this).width() > 400) {
            src = container.data("imgnormal");
            if(src === "") {
                src = container.data("imgsquare");
            }
        } else {
            src = container.data("imgsquare");
            if(src === "") {
                src = container.data("imgnormal");
            }
        }

        container.css({
            "background-image": "url('" + src + "')"
        })

    })
});
