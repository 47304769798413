jQuery(document).ready(function($){
	// Get Window Width and Height
	var windowWidth = $(window).outerWidth();
	var windowHeight = $(window).height();

	// Header scroll function
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('#site-header').outerHeight();
	$(window).scroll(function(event){
		didScroll = true;
	});
	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);
	function hasScrolled() {
		var st = $(this).scrollTop();
		if (st==0) {

			if($("body").hasClass("menuOpen")) {
				$('#site-header').addClass('scrolled white-menu');
			}
			else {
				$('#site-header').removeClass('scrolled white-menu');
			}

		}
		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
		return;
		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight){
			// Scroll Down
			$('#site-header').removeClass('white-menu').addClass('scrolled');
			$('.menuButton').addClass('booking-btn');
			$('.sub-menu').hide();
		} else {
			// Scroll Up
			if(st + $(window).height() < $(document).height()) {
				$('#site-header').addClass('white-menu');
				$('.menuButton').removeClass('booking-btn');
			}
		}
		lastScrollTop = st;
	}

	// Site header function
	if($("#main article div div").first().hasClass("section-container")) {
		$('#site-header').addClass('noTopImage white-menu');
		$('#main').addClass('noTopImage');
	}
	else if($("#main section").first().hasClass("error-404")) {
		$('#site-header').addClass('noTopImage white-menu');
	}
	else {
		$('#site-header').removeClass('noTopImage white-menu');
		$('#main').removeClass('noTopImage');
	}

	// Site menu function
	$(".flaticon-menu").on('tap', function (e) {
		$('body').toggleClass("menuOpen");
		$('.header-right').slideToggle(300);
		$('#site-header').addClass('scrolled white-menu');
		$('body').toggleClass('no-scroll');

		$(document).off("tap").bind("tap", function(e) {
			var container = $(".header-right");
			if(container.is(":visible")) {
				if (!container.is(e.target) && container.has(e.target).length === 0) // ... nor a descendant of the container
				{
					container.hide();
				}
			}
		});
	});

	// Search function
	$(".flaticon-search").on('tap', function (e) {
		$('.search-wrapper').fadeIn(300);
		$('body').addClass('no-scroll');
	});
	$(".search-wrapper i").on('tap', function (e) {
		$('.search-wrapper').fadeOut(300);
		$('body').removeClass('no-scroll');
	});

	// SMS function
	$(".sms-wrapper").each(function() {
		$(this).prepend('<i class="fi flaticon-close"></i>');
	});
	$(".sms-wrapper figcaption").each(function() {
		$(this).appendTo($(this).prev('.vc_single_image-wrapper'));
	});
	$(".header-left").on("tap", function(e) {
		e.preventDefault();
		$(".booking-wrapper").hide();
		if(windowWidth < 768) {
			$('body').addClass('no-scroll');
		}
		else {
			$('body').removeClass('no-scroll');
		}
		setTimeout(function(){
			$(".sms-wrapper").fadeIn(300);
		}, 100)

		$(document).on("tap", function(e) {
			var container = $(".sms-wrapper");
			if(container.is(":visible")) {
				if (!container.is(e.target) && container.has(e.target).length === 0) // ... nor a descendant of the container
				{
					container.hide();
				}
			}
		});
	});
	$(".sms-wrapper i").on('tap', function (e) {
		e.preventDefault();
		$('.sms-wrapper').fadeOut(300);
		$('body').removeClass('no-scroll');
	});

	// Booking function
	$(".booking-wrapper").each(function() {
		$(this).prepend('<i class="fi flaticon-close"></i>');
	});
	$(".booking-wrapper figcaption").each(function() {
		$(this).appendTo($(this).prev('.vc_single_image-wrapper'));
	});
	$(".menuButton").on("tap", function(e) {
		e.preventDefault();
		$(".sms-wrapper").hide();
		if(windowWidth < 768) {
			$('body').addClass('no-scroll');
		}
		else {
			$('body').removeClass('no-scroll');
		}
		setTimeout(function(){
			$(".booking-wrapper").fadeIn(300);
		}, 100)

		$(document).off("tap").bind("tap", function(e) {
			var container = $(".booking-wrapper");
			if(container.is(":visible")) {
				if (!container.is(e.target) && container.has(e.target).length === 0) // ... nor a descendant of the container
				{
					container.hide();
				}
			}
		});
	});
	$(".booking-wrapper i").on('tap', function (e) {
		$('.booking-wrapper').fadeOut(300);
		if(windowWidth < 768) {
			$('.header-right').hide();
		}

		$('body').removeClass('no-scroll');
	});


	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});

	$(".sub-menu a").on("click", function(e) {
		e.stopPropagation();
	});

	var tmrMenu;
	if(windowWidth > 767) {
		$("ul.menu > li.sub-menu-btn").on("mouseenter", function() {
			clearTimeout(tmrMenu);
			var sub = $(this).find(".sub-menu");
			$(".sub-menu").not(sub).hide();
			sub.show();
		}).on("mouseleave", function() {
			var $m = $(this);
			tmrMenu = setTimeout(function() {
				var sub = $m.find(".sub-menu");
				sub.hide();
			}, 200);

		});
		$("ul.menu > li.sub-menu-btn > .sub-menu").on("mouseenter", function() {
			clearTimeout(tmrMenu);
		}).on("mouseleave", function() {
			var $m = $(this);
			tmrMenu = setTimeout(function() {
				$m.hide();
			}, 150);
		});
	}


	$(".sub-menu-btn").each(function() {
		$(this).append('<button class="toggle-sub-menu fa fa-angle-down"></button>');
	});
	$( document ).on( 'click', '.toggle-sub-menu', function(){
		$(this).prev().slideToggle();
	});

});
