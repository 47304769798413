!function(a){a.fn.equalHeights=function(){var b=0,c=a(this);return c.each(function(){var c=a(this).innerHeight();c>b&&(b=c)}),c.css("height",b)},a("[data-equal]").each(function(){var b=a(this),c=b.data("equal");b.find(c).equalHeights()})}(jQuery);

(function($) {

	/* Document ready functions */
	$(document).ready(function (){

		setTimeout(function() {
			$(".puff-grid-container").each(function(){
				$(this).find('.puff-col').equalHeights();
			});
		}, 100);

	});

	/* Resize functions */
	$(window).resize(function() {

		// Get Window Width and Height
		windowWidth = $(window).outerWidth();
		windowHeight = $(window).height();

		setTimeout(function() {
			$(".puff-grid-container").each(function(){
				$(this).find('.puff-col').height('auto');
				$(this).find('.puff-col').equalHeights();
			});
		}, 100);

	});

})(jQuery);
