(function($) {
$(document).ready(function(){

  $('.tb-booking-button-wrapper').closest('.section-wrapper').css('overflow', 'visible');
  $('.tb-booking-button-wrapper').closest('.section').css('overflow', 'visible');
  $('.booking-nav-wrp').closest('.section-wrapper').css('overflow', 'visible');
  $('.booking-nav-wrp').closest('.section').css('overflow', 'visible');

  setupGeneralDatepickers();
  installBooking();

  $('#confbookingform').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

});

function installBooking() {
  var bookingExists = $("#booking_button_form").length;
  if (bookingExists > 0) {
    setupDatepickers();
    hookUpInput();
    constructQuerystring();
  }
}

function setupGeneralDatepickers() {
  // All datepickers.
  $('.datepicker').pickadate();
}

function constructQuerystring() {
  var url = $("#btn_booking_search").attr("data-url");
  var checkInPicker = $("#check_in_date").pickadate('picker');
  var checkOutPicker = $("#check_out_date").pickadate('picker');

  var fields = {
    checkIn: checkInPicker.get("value"),
    checkOut: checkOutPicker.get("value"),
    rooms: $("#num_rooms").val(),
    adults: $("#number_adults").val(),
    children: $("#number_children").val(),
    promotion: $("#promotion_code").val()
  };

  // Build the url.
  url += "&check_in_date=" + fields.checkIn;
  url += "&check_out_date=" + fields.checkOut;
  url += "&rooms=" + fields.rooms;
  url += "&number_adults=" + fields.adults;
  url += "&number_children=" + fields.children;
  url += "&number_infants=" + "0";
  url += "&promotion_code=" + fields.promotion;

  $("#btn_booking_search").attr("href", url);
}

function setupDatepickers() {
  // Check in.
  $("#check_in_date").pickadate({
    format: 'mm/dd/yyyy',
    selectMonths: true,
    onClose: function() {
      var checkInPicker = $("#check_in_date").pickadate('picker');
      var checkOutPicker = $("#check_out_date").pickadate('picker');

      var fromDate = new Date(checkInPicker.get("value"));
      var toDate = new Date(checkOutPicker.get("value"));
      var oneDayForward = new Date();
      oneDayForward.setDate(fromDate.getDate() + 1);
      checkOutPicker.set({
        min: oneDayForward
      });

      if (toDate <= fromDate) {
        checkOutPicker.set({
          'select': oneDayForward
        });
      }

      constructQuerystring();
      $(document.activeElement).blur();
    }
  });

  // Check out.
  $('#check_out_date').pickadate({
    format: 'mm/dd/yyyy',
    selectMonths: true,
    onClose: function() {
      constructQuerystring();
      $(document.activeElement).blur();
    }
  });

  // Set max.
  var checkInPicker = $("#check_in_date").pickadate('picker');
  var checkOutPicker = $("#check_out_date").pickadate('picker');
  var forward = new Date();
  forward.setDate(forward.getDate() + 1);

   checkInPicker.set({
     // Min date is today.
     min: new Date()
   });
   checkOutPicker.set({
     min: forward
   });
}

function hookUpInput() {
  $("#num_rooms").on("change", onValueChanged);
  $("#number_adults").on("change", onValueChanged);
  $("#number_children").on("change", onValueChanged);
  $("#promotion_code").on("change", onValueChanged);
}

function onValueChanged(e) {
  constructQuerystring();
}

})(jQuery);
