function isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
}


(function($) {
	// Get Window Width and Height
	var windowWidth = $(window).outerWidth();
	var windowHeight = $(window).height();

  // Blogg functions
  $(".sidebar .widget_nav_menu h4, .sidebar .widget_categories h4").addClass('fa fa-angle-down');
  $(".sidebar .widget_nav_menu h4, .sidebar .widget_categories h4").on("click", function(){
    if(windowWidth < 980) {
      $(this).next().slideToggle();
    }
  });

  function cookieAccept(){
    $('.cookie').hide();
    $('.cookie').remove();
    localStorage.accepted='1';
  }

	/* Document ready functions */
	$(document).ready(function (){

		// Apply style to a if touch device
    if (isTouchDevice()) {
        $('a').addClass('mobile');
    }
    else {
      $('a').removeClass('mobile');
    }

    if(localStorage.accepted === undefined) {
      $('.cookie').fadeIn(300);
    }
    else {
      $('.cookie').hide();
      $('.cookie').remove();
    }
    $(".cookieBtn").on("click", function() {
      cookieAccept();
    });



	});

	$(window).resize(function () {

		// Get Window Width and Height on resize
		windowWidth = $(window).outerWidth();
		windowHeight = $(window).height();

	});

})(jQuery);
