(function($) {
    $.fn.changeElementType = function(newType) {
        var attrs = {};

        $.each(this[0].attributes, function(idx, attr) {
            attrs[attr.nodeName] = attr.nodeValue;
        });

        this.replaceWith(function() {
            return $("<" + newType + "/>", attrs).append($(this).contents());
        });
    };
})(jQuery);

jQuery(document).ready(function ($) {

    $('.offer.las-upp-med-kod').remove();

    // Toggle Filter menus
    $( document ).on( 'click', '.filter-toggle', function(){

        if ($(this).hasClass('active')) {

            $(this).next().removeClass('active');
            $(this).removeClass('active');

        } else {
            $('.filter-toggle').removeClass('active');
            $('.filter-buttons').removeClass('active');
            $(this).next().addClass('active');
            $(this).addClass('active');
        }

    });

    // Toggle offers

    var hash = window.location.hash.substring(1, window.location.hash.length);
    var parts = hash.split('+');
    var activeIds = [];
    var currentPropertyFilters = [];
    var currentCategoryFilters = [];

    setTimeout(function(){
        if(window.location.hash) {
            for(var i = 0, l = parts.length; i < l; ++i) {
                $('.filter-btn[data-id=' + parts[i] + ']').trigger('click');
            }
        }
    }, 200);

    var $offers = $('.offers .offer');

    $('.property-filter .filter-btn').on('click', filterByProperty);
    $('.category-filter .filter-btn').on('click', filterByCategory);

    function filterByProperty(e) {
        e.preventDefault();

        var $this = $(this);
        var id = $this.data('id');

        $('.property-filter .filter-btn').removeClass('all');
        $this.toggleClass('active');
        if($this.hasClass('active')) {
            currentPropertyFilters.push(id);
        } else {
            var index = currentPropertyFilters.indexOf(id);
            if(index > -1) {
                currentPropertyFilters.splice(index, 1);
            }
        }

        executeFilter();

    }

    function filterByCategory(e) {
        e.preventDefault();

        var $this = $(this);
        var id = $this.data('id');

        $('.category-filter .filter-btn').removeClass('all');
        $this.toggleClass('active');
        if($this.hasClass('active')) {
            currentCategoryFilters.push(id);
        } else {
            var index = currentCategoryFilters.indexOf(id);
            if(index > -1) {
                currentCategoryFilters.splice(index, 1);
            }
        }

        executeFilter();

    }

    function executeFilter() {

        $offers = $('.offers .offer');
        $offers.each(function () {
            $(this).changeElementType('span');
        });
        $offers = $('.offers .offer');


        var propertyHash = currentPropertyFilters.join('+');
        var categoryHash = currentCategoryFilters.join('+');
        var hash;

        for(var i = 0, l = $offers.length, $offer, id, hitPropertyFilter, hitCategoryFilter; i < l; ++i) {
            $offer = $($offers[i]);
            id = $offer.data('id');
            hitPropertyFilter = false;

            for(var j = 0, ll = currentPropertyFilters.length; j < ll; ++j) {
                var idArr = id.split(' ');
                if( $.inArray(currentPropertyFilters[j], idArr) === -1 ) {
                    continue;
                }

                hitPropertyFilter = true;
                break;
            }

            if(currentPropertyFilters.length === 0) {
                $('.property-filter .filter-btn').addClass('all');
                hash = propertyHash + categoryHash;
                document.location.hash = hash;
            } else {
                hash = propertyHash + '+' + categoryHash;
                document.location.hash = hash;
            }

            if(currentPropertyFilters.length > 0 && !hitPropertyFilter) {
                continue;
            }

            if(currentCategoryFilters.length === 0) {
                $offer.changeElementType('div');
                $('.category-filter .filter-btn').addClass('all');
            } else {
                for(var j = 0, ll = currentCategoryFilters.length; j < ll; ++j) {
                    if(id.indexOf(currentCategoryFilters[j]) === -1) {
                        continue;
                    }

                    $offer.changeElementType('div');
                    break;
                }
            }
        }
    }

});