(function($) {

	// Get Window Width and Height
	var windowWidth = $(window).outerWidth();
	var windowHeight = $(window).height();

	/* Document ready functions */
	$(document).ready(function (){

		var swiper = new Swiper('.swiper-container', {
      grabCursor: false,
			loop: true,
			speed: 1500,
			effect: 'fade',
			autoplay: {
        delay: 5000,
      },
    });

    $('.fullscreen, .fullscreen .swiper-slide').css({"height":windowHeight});

		$(".fullscreen .ts-scrolldown").each(function(){
		  $(this).on("click", function() {
				$('html,body').animate({scrollTop: $(this).parent().next().offset().top }, 500);
			});
		});

	});

	/* Resize functions */
	$(window).resize(function() {

		// Get Window Width and Height
		windowWidth = $(window).outerWidth();
		windowHeight = $(window).height();

		$('.fullscreen, .fullscreen .swiper-slide').css({"height":windowHeight});

	});

})(jQuery);
