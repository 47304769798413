jQuery(document).ready(function($){

	$('.newsletterSubmit').on('click', function(e){
		if(validateForm()===false){
			e.preventDefault();
		}  
	});

	function validateForm(){

    var nameReg = /^[A-Za-z]+$/;
    var numberReg =  /^[0-9]+$/;
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

    var email = $('.newsletterEmail').val();
	var names = $('.newsletterName').val();

    var inputVal = new Array(email, names);

    var inputMessage = new Array("e-postadress", "namn");

	var validForm = true;

    $('.error').hide();

        if(inputVal[0] === ""){
            $('.newsletterEmail').after('<span class="error"> Ange en ' + inputMessage[0] + '</span>');
			validForm = false;
        }
        else if(!emailReg.test(email)){
            $('.newsletterEmail').after('<span class="error"> Felaktig e-postadress</span>');
			validForm = false;
        }

		if(inputVal[1] == ""){
            $('.newsletterName').after('<span class="error"> Ange ditt ' + inputMessage[1] + '</span>');
			validForm = false;
        }

		return validForm;

}

});
