function googleTranslateElementInit() {
    new google.translate.TranslateElement({pageLanguage: 'sv', includedLanguages: 'sv,en,da,no,fi,fr,de,es'}, 'google_translate_element');
}

jQuery(document).ready(function ($) {
    'use strict';

    $('head').append('<script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" defer><\/script>');

    $(document.body).on('click', '.lang-wrp', function(e) {
        if (e.target !== this) {
            return;
        }
        $('body').removeClass('lang-open');
        $('.lang-wrp').removeClass('visible');
    });

    // Toggle Search
    $( document ).on( 'click', '.lang-toggle', function(){

        if ($('body').hasClass('lang-open')) {

            $('body').removeClass('lang-open');
            $('.lang-wrp').removeClass('visible');

        } else {

            $('body').addClass('lang-open');
            $('.lang-wrp').addClass('visible');

        }

    });

    // hoisted ---------------------------
    function isTranslated() { // true if the text has been translated
        var currentPH = $($('#placeholders > div')[0]).text();
        return !(originalPH == currentPH);
    }

    function updatePlaceholders() {
        $('#placeholders > div').each(function(i, div){
            placeholders[i].placeholder = $(div).text();
        });
    }

    // Find all placeholders
    var placeholders = document.querySelectorAll('input[placeholder]');

    if (placeholders.length) {
        // convert to array
        placeholders = Array.prototype.slice.call(placeholders);

        // copy placeholder text to a hidden div
        var div = $('<div id="placeholders" style="display:none;"></div>');

        placeholders.forEach(function(input){
            var text = input.placeholder;
            div.append('<div>' + text + '</div>');
        });

        $('body').append(div);

        // save the first placeholder in a closure
        var originalPH = placeholders[0].placeholder;

        // check for changes and update as needed
        setInterval(function(){
            if (isTranslated()) {
                updatePlaceholders();
                originalPH = placeholders[0].placeholder;
            }
        }, 500);
    }

    $(document).on('change', '.goog-te-combo', function() {

        $('.lang-wrp').removeClass('visible');

    });

    $(document).on('click', '.clear-translate', function() {

        $('#\\:1\\.container').contents().find('#\\:1\\.restore').click();

    });
  
});